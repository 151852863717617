import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Seo from "../components/seo"
import { Col, Row } from "react-bootstrap";

// eslint-disable-next-line
export const DatenschutzPageTemplate = ({ title, content, contentComponent }) => {
    const PageContent = contentComponent || Content;

    return (
        <>
            <Row>
                <Col className="col-lg-8 pb-2">
                    <h1 className="page-title title-border">
                        {title}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col className="col-lg-8 offset-lg-2">
                    <PageContent className="content" content={content} />
                </Col>
            </Row>
        </>
    );
};

DatenschutzPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
};

const DatenschutzPage = ({ data }) => {
    const { markdownRemark: post } = data;
    const location = "datenschutz"

    return (
        <Layout location={location}>
            <DatenschutzPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
            />
        </Layout>
    );
};

DatenschutzPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default DatenschutzPage;

export const Head = () => <Seo title="Datenschutz" />

export const datenschutzPageQuery = graphql`
  query DatenschutzPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
